import React from 'react'
import styled from 'styled-components'

import { Container } from 'common/UI'
import { Carousel } from 'common/UI/Carousel'
import { SelectedPatientStoriesStoryblok } from 'common/types'
import { PatientStoryThumb } from 'modules/shared'

type Props = {
  block: SelectedPatientStoriesStoryblok
}

export const SelectedPatientStories = ({
  block,
  ...rest
}: Props): JSX.Element => {
  const { items, variant } = block

  return (
    <section>
      {variant === 'grid' && (
        <Grid {...rest}>
          {items?.map((item) => (
            <PatientStoryThumb key={item.uuid} item={item} />
          ))}
        </Grid>
      )}
      {variant === 'slider' && (
        <Carousel itemsPerView={3} {...rest}>
          {items?.map((item) => (
            <PatientStoryThumb key={item.uuid} item={item} />
          ))}
        </Carousel>
      )}
    </section>
  )
}

const Grid = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(2, 1fr);

    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`
